const mockSetting = {
  pageId: 'login.ftl' as any, // index.ftl | register.ftl | login.ftl | login-reset-password.ftl | ecom-update-phone.ftl | ecom-update-email.ftl
  data: {
    locale: {
      currentLanguageTag: 'zh-CN', // zh-CN | en
    },
    properties: {
      icp_no: '沪ICP备2021012961号',
      icp_link: 'https://beian.miit.gov.cn/#/Integrated/recordQuery',
      security_beian_no: '沪公网安备 31012002005502号',
      security_beian_link:
        'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31012002005502',
    },
    messagesPerField: {
      get: (fieldName: string) => {
        if (
          fieldName === 'phoneNumber' ||
          fieldName === 'phone_number' ||
          fieldName === 'username'
        ) {
          return '';
          // return 'userPhoneAlreadyExisting';
        }
        return '';
      },
    },
    username: 'test',
    // password: 'test',
    code: '123456',
    type: 'code', // 'password'
    message: {
      error: true,
      summary: 'agreementVersionExpired',
      type: 'error',
    } as any,
    social: {
      displayInfo: true,
      providers: [
        {
          alias: 'saml',
          displayName: 'Azure EntraID',
          iconClasses: '',
          loginUrl:
            '/auth/realms/cn-igus-grey/broker/saml/login?client_id=ecom-gateway-client&tab_id=p0j1CJ4z25g&session_code=s0qdSyzONciQb2JZZgf50uTl9yrr4tHwl4qHWQ60igY',
          providerId: 'saml',
        },
        {
          alias: 'saml',
          displayName: 'Azure EntraID2',
          iconClasses: '',
          loginUrl:
            '/auth/realms/cn-igus-grey/broker/saml/login?client_id=ecom-gateway-client&tab_id=p0j1CJ4z25g&session_code=s0qdSyzONciQb2JZZgf50uTl9yrr4tHwl4qHWQ60igY',
          providerId: 'saml',
        },
      ],
    },
  },
};

type MockSetting = typeof mockSetting;

const setting = process.env.NODE_ENV === 'development' ? mockSetting : ({} as MockSetting);

export default setting;
